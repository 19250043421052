/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { message } from "antd";
import DepartureTable from "./DepartureTable";

const DepartureDisplay = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    let interval;
    if (props.selectedStations.length > 0) {
      fetchDataForSelectedStations();
      interval = setInterval(fetchDataForSelectedStations, 60000);
    } else {
      setColumnData([]);
    }
    return () => clearInterval(interval);
  }, [props.selectedStations]);

  const fetchDeparturesAtStop = async (station) => {
    const stationId = station.id;
    const now = new Date();
    const later = new Date(
      now.getTime() + (station.when != null ? station.when : 0) * 60000
    );
    const formattedTime = later.toLocaleTimeString("de-DE", { hour12: false });
    const direction =
      station.direction != null && station.direction !== ""
        ? station.direction
        : null;

    const url = `https://v6.bvg.transport.rest/stops/${stationId}/departures?when=${formattedTime}&results=${
      station.results
    }&suburban=${station.suburban}&subway=${station.subway}&tram=${
      station.tram
    }&bus=${station.bus}&ferry=${station.ferry}&express=${
      station.express
    }&regional=${station.regional}${
      direction != null ? `&direction=${direction}` : ""
    }`;

    const response = await fetch(url);
    return response.json();
  };

  const fetchDataForSelectedStations = async () => {
    try {
      const fetchPromises = props.selectedStations.map(fetchDeparturesAtStop);
      const results = await Promise.all(fetchPromises);
      const columnData = getColumnData(results);
      setColumnData(columnData);
    } catch (error) {
      messageApi.error("Fehler beim Laden der Daten");
      setColumnData([]);
    }
  };

  const getColumnData = (data) => {
    const columnData = [];

    for (let i = 0; i < data.length; i++) {
      const stationData = data[i];
      for (let j = 0; j < stationData.departures.length; j++) {
        const departure = stationData.departures[j];
        const now = new Date();
        const whenDate = departure.when ? new Date(departure.when) : null;
        const diffInMinutes = whenDate
          ? Math.floor((whenDate.getTime() - now.getTime()) / 60000)
          : null;

        columnData.push({
          key: `${departure.stop.id}_${j}`,
          lineName: departure.line.name,
          direction: departure.direction,
          departureName: departure.stop.name,
          when: diffInMinutes,
          remarks: departure.remarks,
        });
      }
    }

    return columnData;
  };

  const columns = [
    {
      title: "Linie",
      dataIndex: "lineName",
      key: "lineName",
    },
    {
      title: "Richtung",
      dataIndex: "direction",
      key: "direction",
    },
    {
      title: "Abfahrt von",
      dataIndex: "departureName",
      key: "departureName",
    },
    {
      title: "Abfahrt in",
      dataIndex: "when",
      key: "when",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.when - b.when,
      render: (text) => <div>{text > 0 ? text : "Jetzt"}</div>,
    },
  ];

  return (
    <div>
      {contextHolder}
      <DepartureTable
        fontSize={props.fontSize}
        columns={columns}
        dataSource={columnData}
        remarksVisibility={props.remarksVisibility}
      />
    </div>
  );
};

export default DepartureDisplay;
